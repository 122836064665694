export const operationGiveThanks = `
# Operation Give Thanks
<div id="video"> https://www.youtube.com/embed/2eGNW8dYs2w?si=Hp3HuFk67NGGI0ZO </div>

</br>
### In 2024 the combination of customers’ direct donations, merchandise sales, vendor partner donations, and a portion of fuel sales totaled $315,146.46.
</br>
Operation Give Thanks is a company-and customer-supported program designed to aid active-duty service members, veterans, and their families using various armed forces related charities in Kwik Fill Country.
</br></br>
<a href="https://www.operationgivethanks.com" id="button">Learn More</a>
</br></br>
</br>
</br>
</br>
`