export const news = `
# News

</br></br>
<em>December 11, 2024</em>
### Kwik Fill Announces 2024 "Operation Give Thanks" Results
</br>
Warren, PA – United Refining Company of Pennsylvania (URC), doing business as Kwik Fill® /
Red Apple Food Marts®, today announced the results of its "Operation Give Thanks" fundraising
campaign conducted during November 2024.
</br></br>

"Operation Give Thanks" is a company, customer,
and vendor partner supported program designed to aid active-duty service members, veterans,
and their families using various armed forces related charities in Kwik Fill Country.
</br></br>

In 2024 the combination of customers' direct donations, merchandise sales, vendor partner
donations, and a portion of fuel sales totaled $315,146.46. This total will be distributed to
Operation Homefront (a national 501(c)(3) nonprofit that commits to allocating resources to
recipients in the Kwik Fill market area), Special Forces Charitable Trust (providing meaningful
and sustainable support to the Army Green Berets, and their families), and Allegheny Valley
Veterans Center (providing supportive housing for homeless veterans in northwestern
Pennsylvania).
</br></br>

Kwik Fill thanks all supporters of the military and veteran community in Kwik Fill country who
participated in "Operation Give Thanks."
</br></br>
Visit:</br>
<a href="https://www.kwikfill.com/operation-give-thanks">www.kwikfill.com/operation-give-thanks</a></br>
<a href="https://www.operationgivethanks.com">www.operationgivethanks.com</a></br>
<a href="https://www.OperationHomefront.org">www.OperationHomefront.org</a></br>
<a href="https://www.specialforcescharitabletrust.org">www.specialforcescharitabletrust.org</a></br>
<a href="https://www.avvc.us">www.avvc.us</a></br>
<hr>

</br></br>
<em>November 1, 2024</em>
### Kwik Fill Launches "Operation Give Thanks"
</br>
Warren, PA – United Refining Company of Pennsylvania, doing business as Kwik Fill® / Red Apple Food Marts®, has launched its third annual "Operation Give Thanks" fundraising campaign. This company-and-customer-supported fundraising program to aid active-duty service members, veterans, and their families raised over $243,000 in 2023. "Operation Give Thanks" will again run from November 1 through November 30th with customers making donations at any store. Kwik Fill will also commit a portion of the sale of every gallon of gasoline and diesel fuel that customers purchase from November 11th (Veterans Day) through November 30th to the campaign.
</br></br>

The combination of customers' direct donations along with the amount committed by Kwik Fill from fuel sales will be donated to Allegheny Valley Veterans Center (a housing facility for homeless veterans serving eight counties in northwestern PA), Special Forces Charitable Trust (dedicated to taking care of our Special Forces Soldiers and their families), and Operation Homefront (a national charity that will direct funds to recipients in the Kwik Fill market area). Customers will be able to make additional donations directly through the "Operation Give Thanks" site at www.operationgivethanks.com. Once again this year Kwik Fill will be selling a special coffee mug as part of the program. This mug entitles purchasers to free coffee through November with the proceeds of mug sales going to "Operation Give Thanks".
</br></br>

Jon Mechling, Assistant VP for Kwik Fill said, "We host many fundraising initiatives throughout the year, but this one receives the most support by far from our customers. It fits so well with our commitment to a strong America, and our employees are especially excited to support this campaign."
</br></br>

Kwik Fill invites all supporters of the military and Veteran community in Kwik Fill country to participate in "Operation Give Thanks".
</br></br>

Visit <a href="https://www.kwikfill.com">www.kwikfill.com</a> or <a href="https://www.operationgivethanks.com">www.operationgivethanks.com</a> to learn more about Kwik Fill and "Operation Give Thanks". Follow Kwik Fill on Facebook, Instagram, and X.
</br></br>

Operation Homefront – <a href="https://www.operationhomefront.org">www.operationhomefront.org</a></br>
Allegheny Valley Veterans Center – <a href="https://www.avvc.us">www.avvc.us</a></br>
Special Forces Charitable Trust – <a href="https://www.specialforcescharitabletrust.org">www.specialforcescharitabletrust.org</a>
</br>
<hr>

</br></br>
<em>July 15, 2024</em>
### Kwik Fill Acquires Pump N Pantry
</br>
Warren, PA – United Refining Company of Pennsylvania announced today their acquisition of
the Pump N Pantry convenience chain based in Montrose, PA. The process of rebranding the 14
Pump N Pantry stores to be integrated into the longstanding and successful Kwik Fill / Red
Apple chain began immediately.
</br></br>

Jon Mechling, Assistant Vice President for Kwik Fill, said, “We are very excited to begin serving
current customers and reaching new ones in north-eastern Pennsylvania. We intend to
augment existing products and services that customers appreciate, with our own popular
prepared foods, tradition of great customer service, and our American-made fuels.” We are
continuously driving our operations to be cost-effective and look forward to delivering value to
all our new and existing customers. Customers are encouraged to visit www.kwikfill.com to
learn more about the chain, examine the store locator map, and enter the free monthly online
giveaways.
</br></br>

Kwik Fill offers a free Kwik Rewards Club Card that customers can use right at the pump to save
three cents on every gallon. Additionally, customers who sign up for a Kwik Fill Charge Card and
use it for payment can save an additional five cents per gallon. Kwik Fill Gift Cards are also very
popular for gifts during any season.
</br></br>

Founded in 1902, United Refining Company is a refiner of North American Crude Oil privately
owned by John Catsimatidis, Chief Executive Officer - Red Apple Group and located in Warren,
Pennsylvania. Along with the refinery, United owns and operates multiple terminals, and with
the addition of the acquisition of Pump N Pantry, over 400 retail locations throughout New
York, Pennsylvania, and Ohio.
</br>
<hr>

</br>
<em>February 27, 2024</em>
</br>
### United Refining Company/Kwik Fill named to Forbes list
</br>
United Refining Company, to include Kwik Fill, has been awarded one of America’s Best Mid-Size Employers by Forbes for the year 2024. See Press Release [here](/assets/secondary/2024_forbes_press.pdf).
<hr>
<em>January 3, 2024</em>
</br>
### Red Apple Group Announces the Promotion of John Catsimatidis Jr to President and Chief Operating Officer
</br></br>
NEW YORK, NY Jan 3, 2024 – As part of the company’s strategic intent to further broaden the role of John A. Catsimatidis Jr, allowing him to further align his efforts with John Catsimatidis Sr, Chairman and CEO of Red Apple Group, Red Apple Group et al. today announced his promotion to President and Chief Operating Officer of the company.
</br></br>
John has been working for the company in different capacities for 12 years, serving as its Chief Investment Officer since 2020, Executive Vice President since 2017, and Vice President from 2011 to 2016. In addition to being responsible for all of Red Apple Group’s
investments, over the last several years John has led its transformation and restructuring team driving operational turnarounds, strategy formulation/implementation, tuck-in M&A, and general operational excellence for Red Apple’s new acquisitions and wholly-owned businesses.
</br></br>
"John has developed strong experience in nearly every division of the company over the last dozen years which leaves him well suited to the role. Together, I look forward to John continuing the growth of the company into the next generation,” stated John Catsimatidis Sr.
</br></br>
<b>Strategic Growth – Multigenerational Vision</b>
</br></br>
In his statement, John A. Catsimatidis Jr observed, "I am honored to step into the role of President and COO of Red Apple Group. The business was started with one grocery store and has grown to what it is today with over $7 billion of revenue, a testament to a successful strategy built on integrity, vision, and an extraordinary work ethic. I am thrilled to play a leadership role to make sure that growth continues for decades to come.”
</br></br>
John will be responsible for overseeing the company’s day-to-day operations while also steering long-term strategic initiatives.
</br></br>
<b>About Red Apple Group:</b>
</br></br>
Red Apple Group, founded in 1968, is a New York-based conglomerate that owns and operates assets in the energy, real estate, logistics, finance, insurance, media and supermarket
industries, including companies such as United Refining Company, United Metro Energy, Red
Apple Real Estate and various entities related to it, Red Apple Investments, Red Apple Media,
and Gristedes and D’Agostino Supermarkets.
</br>
<hr>
<em>March 14, 2023</em></br>
### United Refining Company/Kwik Fill named to Forbes list
United Refining Company, to include Kwik Fill, has been awarded one of America’s Best Mid-Size Employers by Forbes for the year 2023.
</br></br>
`

