import React from "react";
import withRouter from "../functional-hook-wrapper";
import styles from './navigation-banner.module.css';

export class NavigationBanner extends React.Component {
    state = {
        is_logged_in: localStorage.getItem('token')?.length,
    }

    router: any;

    constructor(props: any) {
        super(props);
        this.router = props.router;
    }

    private getWelcomeMessage() {
        let name = localStorage.getItem('first_name');
        
        if (name) {
            return `Welcome, ${name}`
        }

        return 'Welcome'
    }

    private login() {
        window.history.pushState({}, null, '/account/login');
        this.router.navigate('/account/login');
    }

    private register() {
        window.history.pushState({}, null, '/account/register');
        this.router.navigate('/account/register');
    }

    private careers() {
        window.history.pushState({}, null, '/careers');
        this.router.navigate('/careers');
    }

    private kwikpay() {
        window.history.pushState({}, null, '/kwikpay');
        this.router.navigate('/kwikpay');
    }

    private stores() {
        window.history.pushState({}, null, '/stores');
        this.router.navigate('/stores');
    }

    private signOut() {
        localStorage.clear();
        window.location.href = '/'
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.left_inner}>
                        2024 Sounds of Christmas Giveaway! <div style={{color: '#FADF4B', display: 'inline-block', cursor: 'pointer'}} onClick={() => { this.router.navigate('/win') }}>Enter to win</div>
                    </div>
                </div>

                { this.state.is_logged_in ?
                <>
                    <div className={styles.right}>
                        {/* <div className={styles.optional}> */}
                            <div onClick={() => { this.kwikpay() }} className={styles.navigation_item} style={{marginRight: 20}}>
                                <img className={styles.card_icon} style={{ display: 'inline-block', marginRight: 8, transform: 'translateY(4px)' }} src={`${process.env.PUBLIC_URL}/assets/navigation/credit-card.svg`}/>
                                KwikPAY
                            </div>
                            <div onClick={() => {
                                this.careers()
                            }} className={styles.navigation_item} style={{marginRight: 24}}>Careers</div>

                            <div onClick={() => { this.stores() }} className={styles.navigation_item} style={{marginRight: 24}}>Find a Store</div>

                            <div onClick={() => {
                                window.history.pushState({}, null, '/account');
                                this.router.navigate('/account');
                            }} className={styles.navigation_welcome}>{this.getWelcomeMessage()}</div>
                        {/* </div> */}
                    </div>

                    <div onClick={() => { this.signOut() }} className={styles.sign_in}>
                        Sign Out
                    </div>
                </>
                :
                <>
                    <div className={styles.right}>
                        <div onClick={() => { this.kwikpay() }} className={styles.navigation_item} style={{marginRight: 24}}>
                            <img className={styles.card_icon} style={{ display: 'inline-block', marginRight: 8, transform: 'translateY(4px)' }} src={`${process.env.PUBLIC_URL}/assets/navigation/credit-card.svg`}/>
                            KwikPAY
                        </div>
                        <div onClick={() => { this.careers() }} className={styles.navigation_item} style={{marginRight: 24}}>Careers</div>
                        <div onClick={() => { this.stores() }} className={styles.navigation_item} style={{marginRight: 24}}>Find a Store</div>
                        <div onClick={() => { this.register() }} className={styles.navigation_item} style={{marginRight: 0}}>Register</div>
                    </div>

                    <div onClick={() => { this.login() }} className={styles.sign_in}>
                        Sign In
                    </div>
                </>
                }
            </div>
        );
    }
}

export default withRouter(NavigationBanner);